<template>
    <div class="perfil_wrapper mt-3">

      
  
      <b-overlay class="container col-lg-6 m-auto">
        <h1 class="text-center text-muted">Solicitar Avance de Nivel</h1>
        <hr>

        <b-container fluid v-if="paso == 1">
          <b-row>
            <b-col sm="12">
              <p v-html="mensaje"></p>
              
              <p v-if="nivel_siguiente && nivel_siguiente.descripcion">
                Nivel siguiente: {{nivel_siguiente.descripcion}}
              </p>
            </b-col>
            <b-col sm="12" v-if="nivel_siguiente && nivel_siguiente.descripcion && virtual">
              <div class="form-group">
                <label for="sede_id" class="form-label">¿En qué sede deseas  reclamar el siguiente libro?</label>
                <select v-model="sede_id" id="sede_id" class="form-control">
                  <option disabled value=null>Selecciona una sede</option>
                  <option v-for="opcion in sedesFiltradas" :value="opcion.id">{{ opcion.nombre }}</option>
                </select>
              </div>

            </b-col>
          </b-row>
          <b-row v-if="nivel_siguiente && nivel_siguiente.descripcion">
            <b-col class="text-center" sm="12">
              <p>
                ¿Deseas continuar tu solicitud de avance de nivel? 
              </p>
            </b-col>
            <b-col class="text-center" sm="3"></b-col>
            <b-col class="text-center" sm="3">
              <b-btn  variant="success" class="mx-1 my-1" size="sm" block @click="procesar()" :disabled="procesando"> Si</b-btn>
            </b-col>
            <b-col class="text-center" sm="3">
              <b-btn  variant="danger" class="mx-1 my-1" size="sm" block @click="regresar()"> No</b-btn>
            </b-col>
            <b-col class="text-center" sm="3"></b-col>
          </b-row>
          <b-row v-if="!nivel_siguiente">
            <b-col class="text-center">
              <b-btn  variant="info" class="mx-1 my-2" size="md" @click="regresar()"> Aceptar </b-btn>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid v-else>
          <b-row>
            <b-col sm="12" class="my-3">
              <p class="text-center" v-html="mensaje"></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-btn  variant="info" class="mx-1 my-2" size="md" @click="regresar()"> Aceptar </b-btn>
            </b-col>
          </b-row>
        </b-container>

        <hr>
       
        
      </b-overlay>
    </div>
    
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

    import { validationMixin } from 'vuelidate'
    // import { required, email, minLength } from '@vuelidate/validators'

    import { required, minLength } from "vuelidate/lib/validators";
  
    export default {
      mixins: [validationMixin],
      name: 'SolicitarAvance',
      data() {
          return {
            nivel_siguiente:null,
            paso:1,
            sede_id:null,
            mensaje:null,
            form:null,
            procesando:false,
            codigo:null,
            virtual:false
          }
      },
      mounted () {
        this.traerTemaSiguiente();
        this.cursoFinalizado();        
        if(this.sedes.length==0){
          this.fetchSedes()
        }
        if (this.user && this.user.data && this.user.data.sede_id) {
          this.sede_id = this.user.data.sede_id;
        }
      },
      methods:{
        ...mapActions({
          fetchDisponibilidad: 'academico/fetchDisponibilidad',
          fetchCursoFinalizado: 'academico/fetchCursoFinalizado',
          fetchCursoActual: 'academico/fetchCursoActual',
          fetchTipos: 'academico/fetchTipos',
          fetchSedes: 'sedes/fetchLista',
          fetchInformacion: 'student/informacion',
          avanzarNivel: 'academico/avanzarNivel',
          fetchSedeActual:'academico/fetchSedeActual',
        }),
        ...mapMutations({
          setShowNotification: 'setShowNotification'
        }),
        
        traerTemaSiguiente(){
          this.fetchDisponibilidad(false).then((result)=>{
            console.log("REGRESAR")
          }).catch((err) => {
            if (err && err.data && err.data.error && err.data.error === 'No tiene más temas pendientes.') {
              console.log("CONTINUAR")
            } else {
              console.log("REGRESAR")
            }
          })
        },
        regresar() {
          this.$router.push(`/`)
        },
        procesar() {
          if (!['ok','ok_update'].includes(this.codigo)) {
            this.$bvToast.toast('No puede realizar esta acción', {
                title: 'Proceso exitoso',
                autoHideDelay: 5000
            })
            return;
          }
          this.procesando = true;
          var curso = { nivel_id:null, sede_id: null }
          if (this.codigo == 'ok') {
            curso.nivel_id = this.nivel_siguiente.id;
            curso.sede_id = this.sede_id;
          }
          
          this.avanzarNivel(curso).then((result)=>{
            if (result.codigo != 'ok') {
              this.procesando = false;
            }
            if (result && result.mensaje) {
                this.$bvToast.toast(result.mensaje, {
                    title: 'Proceso exitoso',
                    autoHideDelay: 5000
                })
                setTimeout(() => {
                  this.fetchSedeActual()
                  this.fetchCursoActual().then(() => {
                    this.$router.push(`/`)
                  })
                },3000)
            }
          }).catch((err) => {
            console.err(err)
          })
        },
        cursoFinalizado(){
            this.fetchCursoFinalizado().then((result)=>{
              if (!result || (result && !result.dato)) {
                  this.$router.push(`/`)
              } else {
                this.codigo = result.codigo;
                this.mensaje = result.mensaje;
                this.virtual = result.dato ? result.dato.virtual : false; 
                if (result.codigo == 'ok') {
                  this.nivel_siguiente = result.dato.nivel_siguiente;
                  this.paso = 1;
                  this.consultarNotas();
                } else if (result.codigo == 'ok_update') {
                  this.paso = 1;
                  this.consultarNotas();
                } else {
                  this.paso = 2;
                }
              }
          }).catch((err) => {
            console.err(err)
          })
        },
        consultarNotas() {
          this.fetchInformacion().then((result) => {
            this.form = result;
          }).catch((error) => {
            console.error(error)
          })
        }
          
      },
      computed:{
        ...mapState({
          user: state => state.auth.user,
          siguienteTema: state => state.academico.siguienteTema, 
          // sedeActual: state => state.academico.sedeActual, 
          // cursoActual: state => state.academico.cursoActual,
          // sedes: state  => state.sedes.lista,
          show_notification: state => state.show_notification,
          tipos_documentos: state => state.tipoDocumento.lista,
          estados_civiles: state => state.estadoCivil.lista,
          lista_regimen_salud: state => state.regimenSalud.lista,
          lista_nivel_formacion: state => state.nivelFormacion.lista,
          ocupaciones: state => state.ocupacion.lista,
          discapacidades: state => state.discapacidad.lista,
          lista_grupo_etnico: state => state.grupoEtnico.lista,
          // curso_finalizado: state => state.academico.curso_finalizado, 
          sedes: state  => state.sedes.lista,
        }),
        ...mapGetters({
          // getSede: 'sedes/getSede',
        }),
        // sedeOriginal(){
        //   return this.getSede(this.user.data.sede_original_id)
        // }, 
        validation() {
          return this.form.tipo_documento ? true : false
        },
        sedesFiltradas(){
          return this.sedes.filter(element=>{
            return element.es_virtual == 0 
          })
        }
        
      }
      
    }
  </script>
  
  <style scope>
  
      .perfil_wrapper .container{
        background-color: #fff;
        padding: 15px;
      }
  </style>