<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Nueva Programación</h1>
        
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-button class="nav-link" @click="actualizarDisponibilidad" variant="link" >Actualizar</b-button>
          </b-navbar-nav>

      </div>
    </b-navbar>
    <div class="container-fluid  mt-3">
      <div class="col-lg-6 m-auto p-0">
      	<h1 class="text-center mb-3 text-muted">Nueva Programación</h1>
        <hr>
        <form class="form-horizontal" v-if="cursoActual">
          <div class=" row">
            <label class="col-4">Curso:</label>
            <div class="col-8">
                <span>{{ cursoActual.nombre }}</span>
            </div>
          </div>

          <div class="row" v-if="siguienteTema">
            <label class="col-4">Tema:</label>
            <div class="col-7">
                <span>{{ siguienteTema.nombre }}</span>
            </div>
            <div v-if="siguienteTema && siguienteTema.notificacion_mostrar && siguienteTema.notificacion_texto" class="col-1">
              <b-button variant="link" @click="mostrarNotificacionTema()">
                <b-icon  icon="exclamation-circle-fill" variant="info"></b-icon>
              </b-button>
            </div>
          </div>

          <div class="row" v-if="siguienteTema && siguienteTema.tipoObj">
            <label class="col-4">Salón:</label>
            <div class="col-8">
                <span>{{ siguienteTema.tipoObj.nombre_largo }}</span>
            </div>
          </div>


          <div class="row" v-if="sedes.length>0 && sedeActual">
            <label class="col-4">Sede:</label>
            <div class="col-8">
              <span>{{ sedeActual.nombre }}</span>
              <b-button class="ml-3" size="sm" variant="link" v-if="cambiandoTema==false"
                  @click="cambiandoTema = true">
                  Cambiar
                </b-button>
            </div>
          </div>


          <div v-if="dias && dias.length > 0" class="form-group row">
            <label class="col-4">Dia:</label>
            <div class="col-8">
              <select v-model="dia" class="form-control">
               <option disabled value="null">Please select one</option>
                <option v-for="dia in dias" :value="dia">{{ dia.texto }}</option>
              </select>
            </div>
          </div>

          <div v-if="posibles_horas && posibles_horas.length > 0" class="text-center">

            <b-list-group style="max-width: 380px;margin: auto;">
              <b-list-group-item v-for="(posible_hora,index) in posibles_horas" :key="index" class="d-flex justify-content-between align-items-center py-2">
              {{ posible_hora.nombre_corto }}  <!-- ({{posible_hora.disponible}} cupos) -->
                <b-button class="" size="sm" variant="success" v-if="posible_hora.disponible>0"
                  @click="seleccionarHora(posible_hora)">
                  Programar
                </b-button>
                <div v-else-if="posible_hora.mensaje_cierre">
                  <strong style="font-size: 12px;">Franja cerrada:</strong><br>
                  <span style="font-size: 12px;">{{posible_hora.mensaje_cierre}}</span>
                  
                </div>
                <!--
                <b-button class="" size="sm" variant="danger" v-else-if="posible_hora.mensaje_cierre"
                  @click="consultarMasOpciones(posible_hora)">
                  {{posible_hora.mensaje_cierre}}
                </b-button>
              -->
                <b-button class="" size="sm" variant="info" v-else
                  @click="consultarMasOpciones(posible_hora)">
                  Más Opciones
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </div>
          
          <span v-if="lastTime" class="text-muted" style="font-size: 8px;">Última actualización: {{ lastTime }} </span>
          <hr>

          <div v-if="mensaje" class="mt-1 alert alert-dark" v-html="mensaje"></div>
        </form>
        <b-button class="nav-link" to="/" variant="link" >Mis clases</b-button>
        <hr>
        <div v-if="cambio_de_nivel == 1">
          <b-button class="nav-link" :to="{ name: 'finalizar-nivel' }" variant="success" >Finalizar nivel</b-button>
          <hr>
        </div>
        <div v-else-if="cambio_de_nivel == 2">
          <b-button class="nav-link" :to="{ name: 'avanzar-nivel' }" variant="success" >Avance de nivel</b-button>
          <hr>
        </div>
        <div>
          <h2>Instrucciones</h2>
          <p>
            Ahora puedes programar hasta con 60 minutos antes del inicio de cada bloque.
<br>
Ten en cuenta:
<br>
<ul>
  <li>Si la programación se hace dentro de las 3 horas antes del bloque ya no se podrá cancelar. </li>
  <li>La programación se cierra en el minuto 60 antes del inicio del bloque.</li>
  <li>La programación continuará sujeta a disponibilidad con la cancelación de clases de otros estudiantes.</li>
  <li>Recuerda que si no cancelas clase y no asistes, el no canceló se activará automáticamente en el sistema y ya no podrás tomar la lección programada (se asumirá como vista).</li>
  <li>Para aumentar la posibilidad de habilitar los cupos de los estudiantes que cancelan nos es necesario cambiar el tiempo de cancelación a 24 horas.</li>
  <li>Si no cancelas la clase, no asistes y deseas retomar la lección el director de sede solo te podrá habilitar la clase pagando el valor de un refuerzo.</li>
</ul>
El sistema habilitará un día más de programación en cada bloque:
<br>
<ul>
  <li>Ahora tendrás un día más de posibilidad de programación cuando la disponibilidad de un bloque se agote.</li>
  <li>Recuerda que si programas una clase el sistema solo te mostrará la disponibilidad después de esa programación.</li>
  <li>Recuerda que el sistema te permite hasta 5 bloques programados a la vez.</li>
  <li>La rigurosidad de tu asistencia te garantizará éxito en tu proceso de aprendizaje.</li>
</ul>

La programación se habilitará a las 4:45 am cada día.
<br>
<ul>
  <li>Para garantizar un mejor servicio de programación habilitaremos la programación desde las 4:45 am del día anterior, ya no será desde las 00:00 am como veniamos trabajando.</li>
</ul>
          </p>
        </div>
      </div>
    </div>
    <b-modal  no-close-on-backdrop hide-header-close ok-title="Programar"
    @ok="reservar" @cancel="cancelarConfirmacion" @hide="cancelarConfirmacion"
    v-model="confirmando"   id="modalConfirmacion" >
      <div v-if="agendable">
      Confirmo mi solicitud para tomar la clase {{ siguienteTema.nombre }}
      el día {{ dia.texto }} en la franja de {{ horarioSeleccionado.nombre_corto }} en la sede {{ sedeActual.nombre }}.

      <br>
      <strong v-if="horarioSeleccionado.politica_cancel">{{horarioSeleccionado.politica_cancel}}</strong>
      </div>
    </b-modal>
    
    <b-modal  no-close-on-backdrop hide-header-close ok-only ok-title="Entiendo" @hide="mostrarNotificacionTema"
      v-model="mostrandoInstrucciones"   id="modalInstrucciones">
      Apreciado estudiante: el sistema mostrará la disponibilidad de horarios a partir del primer bloque programado.
    </b-modal>
    
    <b-modal  no-close-on-backdrop hide-header-close ok-only ok-title="Omitir"
      v-model="mostrandoNotificacionTema"   id="modalNotificacionTema" :title="tituloNotificacionTema">
      <p>
        {{textoNotificacionTema}}
      </p>
      <br>
      <p class="text-center">
        <b-link v-if="enlaceNotificacionTema" :href="enlaceNotificacionTema" target="_blank">Más información</b-link>
      </p>
    </b-modal>


    <b-modal  @ok="procesarAceptarTerminos" @cancel="procesarDenegarTerminos" 
    @hide="procesarDenegarTerminos" 
    no-close-on-backdrop   ok-title="Acepto" cancel-title="No Acepto"
    v-model="mostrarTyC"   id="modalTerminosycondiciones"
    title="Términos y Condiciones de Uso" >
    <div v-if="terminos_y_condiciones">
      {{terminos_y_condiciones}}
    </div>
    </b-modal>

    <b-modal  @ok="procesarAceptarSegundaOpcion" @cancel="procesarCancelarSegundaOpcion" 
    @hide="procesarCancelarSegundaOpcion" 
    no-close-on-backdrop   ok-title="Programar" cancel-title="No Acepto"
    v-model="mostrandoSegundaOpcion"   id="modalSegundaOpcion" 
    title="Cupos disponibles">
      <div v-if="getHoraSegundaOpcion.disponible > 0">
          
          Encontramos cupo para que pueda programar su clase {{segundaOpcion.tema.nombre}} el día {{segundaOpcion.horarios.texto}} a las {{getHoraSegundaOpcion.nombre_corto}}<br>
          Quiere aceptar esta opción?
      </div>
      <div v-else>
          NO ENCONTRAMOS MÁS OPCIONES
      </div>
    </b-modal>

    <b-modal hide-header hide-footer size="sm" v-model="mostrandoNoHaySegundaOpcion"   id="modalNoHaySegundaOpcion" >
          NO ENCONTRAMOS MÁS OPCIONES
    </b-modal>

    <b-modal  no-close-on-backdrop hide-header-close ok-title="Cambiar" cancel-title="Cancelar"
    @ok="procesarCambiarSede" @cancel="nuevaSede=null" @hide="nuevaSede=null"
    v-model="cambiandoTema"   id="modalCambiarTema" >
      
      <div v-if="sedes.length>0" class="form-group row">
        <label class="col-4 col-form-label">Seleccione:</label>
        
        <div class="col-12"  style="margin-bottom: 20px;">
            <input v-model="tipoSedeSelected" type="radio"  name="tipoSede" value="virtual" />Virtual<br>
            <input v-model="tipoSedeSelected" type="radio"  name="tipoSede" value="presencial" />Presencial<br>
        </div>

        <div class="col-12">
          <select v-model="nuevaSede" class="form-control">
            <option disabled value=null>Selecciona una sede</option>
            <option v-for="opcion in sedesFiltradas" :value="opcion.id">
            {{ opcion.nombre }}
            </option>
          </select>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';


  export default {
    name: 'programar',
    data () {
      return {
        solicitud : null,
        diaSeleccionado : null,
        horarioSeleccionado : null,
        error : null,
        mensaje : null,
        confirmando : false,
        mostrandoInstrucciones : false,
        mostrandoNotificacionTema : false,
        mostrandoTyC : false,
        segundaOpcion: null,
        tituloNotificacionTema:null,
        textoNotificacionTema:null,
        enlaceNotificacionTema:'#',
        mostrandoSegundaOpcion: false,
        mostrandoNoHaySegundaOpcion: false,
        cambiandoTema: false,
        nuevaSede: null,
        tipoSedeSelected: 'presencial',
        cambio_de_nivel:0//1:Finalizar Nivel,2:Avanzar Nivel,0:No hacer nada
        
      }
    },
    
    mounted () {
      this.fetchClases().then(reponse=>{
        if(!this.debe_aceptar_terminos && this.clasesPendientes.length==0){
          this.mostrandoInstrucciones = true
        }
      })
      if(this.sedes.length==0){
        this.fetchSedes()
      }

      this.actualizarDisponibilidad()
    },
    methods:{
      ...mapActions({
        fetchTipos: 'academico/fetchTipos',
        fetchCursoActual: 'academico/fetchCursoActual',
        fetchHorarios: 'academico/fetchHorarios', 
        fetchDisponibilidad: 'academico/fetchDisponibilidad',
        agendar: 'academico/agendar',
        fetchClases: 'academico/fetchClases',
        aceptarTerminos: 'academico/aceptarTerminos',
        fetchSedes: 'sedes/fetchLista',
        cambiarSede: 'sedes/cambiarSede',
        fetchSedeActual:'academico/fetchSedeActual',
        fetchCursoFinalizado: 'academico/fetchCursoFinalizado',
      }),
      ...mapMutations({
        setHorariosDisponibles : 'academico/setHorariosDisponibles',
        startProcessing : 'startProcessing',
        stopProcessing : 'stopProcessing',
        setWarning : 'setWarning',
      }),
      seleccionarHora(value){
        this.horarioSeleccionado = value
        this.confirmando = true
      },
      cancelarConfirmacion(){
        this.horarioSeleccionado = null
      },
      actualizarDisponibilidad(){
        this.reiniciar()
        this.fetchDisponibilidad().then((result)=>{
          if(this.tipos.length==0){
            this.fetchTipos()
          }
          if(!this.cursoActual){
            this.fetchCursoActual()
          }
        }).catch((err) => {
          if (err && err.data && err.data.error && err.data.error === 'No tiene más temas pendientes.') {
            // Activar cambio de nivel
            this.cursoFinalizado();
            
          }
        })
      },
      cursoFinalizado(){
          this.fetchCursoFinalizado().then((result) => {
            if (result.codigo == 'no') {
              
              this.cambio_de_nivel = 1;
            } else if (result.codigo == 'filled') {
              this.cambio_de_nivel = 0;
            } else if (result.codigo == 'inactivo') {
              this.cambio_de_nivel = 0;
            } else {
              this.cambio_de_nivel = 2;
            }
        }).catch((err) => {
          console.err(err)
        })
      },
      actualizarHorarios(){

        this.fetchHorarios()
      },
      reservar(){
        let tipo_solicitud='normal'
        let tipo_clase='regular'

        if(this.solicitud=='normal'){
          tipo_solicitud='normal'
          tipo_clase='regular'
        }else if(this.solicitud=='lab'){
          tipo_solicitud='asistente'
          tipo_clase='lab'
        }else if(this.solicitud=='tutoria'){
          tipo_solicitud='tutoria'
          tipo_clase='regular'
        }
        tipo_solicitud = 'normal'  //FORZAMOS A NORMAL POR QUE YA NO HAY OPCIONES
        this.agendar({
          
          tema_id:null,
          hora:this.horarioSeleccionado.hora,
          dia:this.diaSeleccionado.fecha,
          tipo_clase:tipo_clase,
          tipo_solicitud:tipo_solicitud,
        }).then((apiResponse)=>{
          if(apiResponse.error){
            this.error = apiResponse.error
          }else{
            let texto = "Programación exitosa para "+apiResponse.fecha+" "+apiResponse.hora+".<br>"
            if(apiResponse.mensaje){
              this.mensaje = texto+' <strong>'+apiResponse.mensaje+'</strong>'
            }else{
              this.mensaje = texto
            }
            
          }
        }).finally(()=>{
          this.reiniciar()
          this.fetchClases()
          this.actualizarDisponibilidad()
        })
      },
      reiniciar(){
        this.diaSeleccionado = null
        this.horarioSeleccionado = null
        this.error = null
        this.setHorariosDisponibles(null)
      },
      procesarAceptarTerminos(){
        this.aceptarTerminos()
      },
      procesarDenegarTerminos(){
        if(this.debe_aceptar_terminos){
          this.$router.push('/')
        }
      },
      consultarMasOpciones(posible_hora){
        let payload = {
          tema_id: this.siguienteTema.id,
          fecha:this.diaSeleccionado.fecha,
          hora:posible_hora.hora,
        }
        this.startProcessing()
        Vue.http.post('ver_mas_opciones',payload).then(
          response =>{
            this.segundaOpcion =  response.data
            if(this.getHoraSegundaOpcion.disponible>0){
              this.mostrandoSegundaOpcion = true  
            }else{
              this.mostrandoNoHaySegundaOpcion = true
              setTimeout(() => {
                this.mostrandoNoHaySegundaOpcion = false
                this.segundaOpcion =  null
              },3000)
            }
            
          }
        ).catch(error=>{
          
        }).finally(()=>{
          this.stopProcessing()
        })
      },
      procesarAceptarSegundaOpcion(){

        this.agendar({
          
          tema_id:this.segundaOpcion.tema.id,
          hora:this.getHoraSegundaOpcion.hora,
          dia:this.segundaOpcion.horarios.fecha,
          tipo_clase:null,
          tipo_solicitud:'normal',
          segunda_opcion: true
        }).then((apiResponse)=>{
          if(apiResponse.error){
            this.error = apiResponse.error
          }else{
            let texto = "Programación exitosa para "+apiResponse.fecha+" "+apiResponse.hora+"."
            if(apiResponse.mensaje){
              this.mensaje = texto+' '+apiResponse.mensaje
            }else{
              this.mensaje = texto
            }
            
          }
        }).finally(()=>{
          this.reiniciar()
          this.fetchClases()
          this.actualizarDisponibilidad()
        })


      },
      procesarCancelarSegundaOpcion(){
        this.mostrandoSegundaOpcion = false
        this.segundaOpcion = null
      },
      procesarCambiarSede(){
        this.cambiarSede({sede_id:this.nuevaSede}).then((apiResponse)=>{
          console.log('Sede cambiada')
          this.setWarning(apiResponse.mensaje)
        }).finally(()=>{
          this.nuevaSede = null
          this.actualizarDisponibilidad()
          this.fetchSedeActual()
        })
      },
      mostrarNotificacionTema() {
        if (this.siguienteTema && this.siguienteTema.notificacion_mostrar && this.siguienteTema.notificacion_texto) {
          this.mostrandoNotificacionTema = true;
          this.textoNotificacionTema = this.siguienteTema.notificacion_texto
          this.enlaceNotificacionTema = this.siguienteTema.notificacion_enlace
          this.tituloNotificacionTema = this.siguienteTema.nombre;
        }
      }

    },
    computed:{
      ...mapState({
        user: state => state.auth.user, 
        cursoActual: state => state.academico.cursoActual, 
        siguienteTema: state => state.academico.siguienteTema, 
        tipos: state => state.academico.tipos,
        dias: state => state.academico.horariosDisponibles,
        ultima_actualizacion_horarios: state => state.academico.ultima_actualizacion_horarios,
        debe_aceptar_terminos: state => state.academico.debe_aceptar_terminos,
        terminos_y_condiciones: state => state.academico.terminos_y_condiciones,
        sedes: state  => state.sedes.lista,
        sedeActual: state => state.academico.sedeActual,
      }),
      ...mapGetters({
        clasesPendientes: 'academico/clasesPendientes',
        getSede: 'sedes/getSede',
      }),
      
      mostrarTyC: {
        get () {
          return this.debe_aceptar_terminos || this.mostrandoTyC
        },
        set (value) {
          this.mostrandoTyC = value
        }
      },
      dia: {
        get () {
          return this.diaSeleccionado;
        },
        set (value) {
          this.diaSeleccionado = value;
        }
      },
      hora: {
        get () {
          return this.horarioSeleccionado;
        },
        set (value) {
          this.horarioSeleccionado = value;
        }
      },
      posibles_horas: {
        get () {
          if(this.diaSeleccionado){
            return this.diaSeleccionado.horas;
          }else{
            return null
          }
          
        },
        set (value) {
          
        }
      },
      agendable: {
        get () {
          return (this.siguienteTema && this.dias && this.diaSeleccionado && this.horarioSeleccionado)
        },
        set(value){}
      },
      lastTime(){
        if(this.ultima_actualizacion_horarios){
          return this.ultima_actualizacion_horarios.toLocaleString('co-ES')  
        }
        return null
      },
      getHoraSegundaOpcion(){
        if(this.segundaOpcion && this.segundaOpcion.horarios && this.segundaOpcion.horarios.horas && this.segundaOpcion.horarios.horas.length>0){
          return this.segundaOpcion.horarios.horas[0]
        }
        return false
      },
      sedesFiltradas(){
        return this.sedes.filter(element=>{
          if(this.tipoSedeSelected=='presencial'){
            return element.es_virtual == 0 
          }else{
            return element.es_virtual == 1
          }
          
        })
      },

      /*
      sedeActual(){
        return this.getSede(this.user.data.sede_id)
      },
      */
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

