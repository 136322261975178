<template>
    <div class="perfil_wrapper mt-3" ref="top">

      
  
      <b-overlay class="container col-lg-6 m-auto" :show="!form || (form && !form.nota_final)">
        <h1 class="text-center text-muted">Finalizar Nivel</h1>
        <hr>

        <b-form  @submit.stop.prevent="onSubmit">
          <b-container fluid v-if="paso == 1">
            <b-row>
              <b-col sm="12">
                <p v-if="form && form.nota_final">
                  Tu nota acumulada es {{form.nota_final}}. Esta sería la nota registrada para tu curso de {{form.curso_actual && form.curso_actual.nombre}}. Si deseas continuar selecciona <b>Si</b>, si por el contrario deseas solicitar un supletorio (presentar de nuevo un examen) lo puedes hacer en el siguiente <a href="https://americanschoolway.edu.co/constancias-y-certificaciones/" target="_blank">enlace</a>.
                </p>
              </b-col>
            </b-row>
            <b-row v-if="form && form.temas">
              <b-col sm="12">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="light"> <!---->
                    <b-th>Tema</b-th>
                    <b-th>Nota</b-th>
                    <b-th>Valor</b-th>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="tema in form.temas" :key="tema.id">
                      <b-td>{{tema.nombre}}</b-td>
                      <b-td>{{tema.nota}}</b-td>
                      <b-td>{{((((tema.nota / tema.rango_nota) * tema.peso_nota)*5)/100).toFixed(3) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="2"><b>NOTA FINAL</b> </b-td>
                      <b-td><b>{{ form.nota_final }}</b></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

              </b-col>
              <b-col class="text-center" sm="12">
                <p>
                  ¿Deseas continuar tu solicitud de finalizar nivel? 
                </p>
              </b-col>
              <b-col class="text-center" sm="3"></b-col>
              <b-col class="text-center" sm="3">
                <b-btn  variant="success" class="mx-1 my-1" size="sm" block @click="continuar(2)"> Si</b-btn>
              </b-col>
              <b-col class="text-center" sm="3">
                <b-btn  variant="danger" class="mx-1 my-1" size="sm" block @click="continuar(1)"> No</b-btn>
              </b-col>
              <b-col class="text-center" sm="3"></b-col>
            </b-row>
          </b-container>

          <b-container fluid v-if="paso == 2" style="height: 180px;">
            <b-row>
              <b-col sm="12">
                <p v-if="form && form.nota_final">
                  Al continuar, tu nota ({{form.nota_final}}) quedará registrada en nuestro sistema y no podrá ser modificada.
                </p>
              </b-col>
            </b-row>
            <b-row v-if="form && form.temas">
              <b-col class="text-center" sm="12">
                <p>
                  ¿Deseas continuar tu solicitud de finalizar nivel? 
                </p>
              </b-col>
              <b-col class="text-center" sm="3"></b-col>
              <b-col class="text-center" sm="3">
                <b-btn  variant="success" class="mx-1 my-1" size="sm" block @click="continuar(3)"> Si</b-btn>
              </b-col>
              <b-col class="text-center" sm="3">
                <b-btn  variant="danger" class="mx-1 my-1" size="sm" block @click="continuar(1)"> No</b-btn>
              </b-col>
              <b-col class="text-center" sm="3"></b-col>
            </b-row>
          </b-container>
  
          <b-container fluid v-else-if="paso == 3">
            <p>
              {{ user.data.primer_nombre }}, <br><br>
              Para continuar con tu solicitud de finalizar nivel es necesario confirmar y/o actualizar los siguientes datos. De esta manera mantendremos tu información actualizada en nuestra base académica y en la secretaria de educación: 
            </p>
            <br>
            <b-row class="my-2">
              <b-col sm="3">
                <strong class="form-label my-1">Nombres:</strong>
              </b-col>
              <b-col sm="9" class="my-1">{{ user.data.primer_nombre }} {{ user.data.segundo_nombre }}</b-col>
            </b-row>
            <b-row class="my-2">
              <b-col sm="3"><strong class="form-label my-1">Apellidos:</strong></b-col>
              <b-col sm="9" class="my-1">{{ user.data.primer_apellido }} {{ user.data.segundo_apellido }}</b-col>
            </b-row>
            <b-row class="my-2">
              <b-col sm="3"><strong class="form-label my-1">Correo:</strong></b-col>
              <b-col sm="9" class="my-1">{{ user.data.email }}</b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="tipo_documento" class="form-label">Tipo documento:</label>
                </b-col>
                <b-col sm="9">
                  <!-- <div v-if="tipodocumento">
                    {{ tipodocumento }}
                  </div>
                  <div v-else>
                    
                  </div> -->
                  <b-form-select id="tipo_documento" v-model="$v.form.tipo_documento.$model" class="form-control"
                    :state="validateState('tipo_documento')"
                    aria-describedby="tipo_documento-live-feedback">
                      <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                      <b-form-select-option v-for="td in tipos_documentos" :key="td.codigo" :value="td.codigo">{{ td.descripcion }}</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback id="tipo_documento-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                  
                </b-col>
            </b-row>
  
            <b-row class="my-2">
              
                <b-col sm="3"><strong class="form-label my-1">Número de documento:</strong></b-col>
                <b-col sm="9" class="my-1">{{ user.data.numero_documento }}</b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="estado_civil_id" class="form-label">Estado civil:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="estado_civil_id" v-model="$v.form.estado_civil_id.$model" class="form-control"
                    :state="validateState('estado_civil_id')"
                    aria-describedby="estado_civil_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in estados_civiles" :key="item.id" :value="item.id">{{ item.descripcion }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="estado_civil_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                    
                </b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="fecha_nacimiento" class="form-label">Fecha de nacimiento:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input id="fecha_nacimiento" type="date" v-model="$v.form.fecha_nacimiento.$model" :state="validateState('fecha_nacimiento')"></b-form-input>
                    <b-form-invalid-feedback id="fecha_nacimiento-live-feedback">Por favor ingrese una fecha valida.</b-form-invalid-feedback>
                </b-col>
            </b-row>

            <b-row class="my-2">
                <b-col sm="3">
                    <label for="genero" class="form-label">Genero:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="genero" v-model="$v.form.genero.$model" class="form-control"
                    :state="validateState('genero')"
                    aria-describedby="genero-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in GENEROS" :key="item" :value="item">{{ item }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="estrato-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>

            <b-row class="my-2">
                <b-col sm="3">
                    <label for="pais_origen" class="form-label">Pais:</label>
                </b-col>
                <b-col sm="9">

                    <vue-typeahead-bootstrap :serializer="s => s.country_name" @hit="hitCountry($event)" :data="countries" v-model="countrySearch" :state="validateState('pais_origen')" />
                    <b-form-select v-show="false" id="pais_origen" v-model="$v.form.pais_origen.$model" class="form-control"
                    :state="validateState('pais_origen')"
                    aria-describedby="pais_origen-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in countries" :key="item.country_name" :value="item.country_name">{{ item.country_name }}</b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback id="pais_origen-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>

                </b-col>
            </b-row>
          
          <b-row class="my-2">
              <b-col sm="3">
                  <label for="departamento_origen" class="form-label">Departamento/Estado/Provincia:</label>
              </b-col>
              <b-col sm="9">
                  <vue-typeahead-bootstrap :serializer="s => s.state_name" @hit="hitState($event)" :data="states" v-model="stateSearch" :state="validateState('departamento_origen')" />
                  <b-form-select v-show="false" id="departamento_origen" v-model="$v.form.departamento_origen.$model" class="form-control"
                  :state="validateState('departamento_origen')"
                  aria-describedby="departamento_origen-live-feedback">
                      <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                      <b-form-select-option v-for="item in states" :key="item.state_name" :value="item.state_name">{{ item.state_name }}</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback id="departamento_origen-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
              </b-col>
          </b-row>
        
        <b-row class="my-2">
            <b-col sm="3">
                <label for="lugar_origen" class="form-label">Lugar de origen:</label>
            </b-col>
            <b-col sm="9">
                <vue-typeahead-bootstrap :serializer="s => s.city_name" @hit="$v.form.lugar_origen.$model = $event ? $event.city_name : null" :data="cities" v-model="citySearch" :state="validateState('lugar_origen')" />
                <b-form-select v-show="false" id="lugar_origen" v-model="$v.form.lugar_origen.$model" class="form-control"
                :state="validateState('lugar_origen')"
                aria-describedby="lugar_origen-live-feedback">
                    <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                    <b-form-select-option v-for="item in states" :key="item.city_name" :value="item.city_name">{{ item.city_name }}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="lugar_origen-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
            </b-col>
        </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="estrato" class="form-label">Estrato:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="estrato" v-model="$v.form.estrato.$model" class="form-control"
                    :state="validateState('estrato')"
                    aria-describedby="estrato-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in ESTRATOS" :key="item" :value="item">{{ item }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="estrato-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="regimen_salud_id" class="form-label">Régimen de salud:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="regimen_salud_id" v-model="$v.form.regimen_salud_id.$model" class="form-control"
                    :state="validateState('regimen_salud_id')"
                    aria-describedby="regimen_salud_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in lista_regimen_salud" :key="item.id" :value="item.id">{{ item.nombre }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="regimen_salud_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>
            
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="nivel_educativo_id" class="form-label">Nivel de formación:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="nivel_educativo_id" v-model="$v.form.nivel_educativo_id.$model" class="form-control"
                    :state="validateState('nivel_educativo_id')"
                    aria-describedby="nivel_educativo_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in lista_nivel_formacion" :key="item.id" :value="item.id">{{ item.descripcion }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="nivel_educativo_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="ocupacion_id" class="form-label">Ocupación:</label>
                </b-col>
                <b-col sm="9">
                  
                    <vue-typeahead-bootstrap :serializer="s => s.descripcion" @hit="$v.form.ocupacion_id.$model = $event ? $event.id : null" :data="ocupaciones" v-model="ocupacionSearch" :state="validateState('ocupacion_id')" />

                    <b-form-select v-show="false" id="ocupacion_id" v-model="$v.form.ocupacion_id.$model" class="form-control"
                    :state="validateState('ocupacion_id')"
                    aria-describedby="ocupacion_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in ocupaciones" :key="item.id" :value="item.id">{{ item.descripcion }}</b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback id="ocupacion_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="discapacidad_id" class="form-label">Discapacidad:</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select id="discapacidad_id" v-model="$v.form.discapacidad_id.$model" class="form-control"
                    :state="validateState('discapacidad_id')"
                    aria-describedby="discapacidad_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in discapacidades" :key="item.id" :value="item.id">{{ item.descripcion }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="discapacidad_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>
  
            <b-row class="my-2">
                <b-col sm="3">
                    <label for="grupo_etnico_id" class="form-label">Seleccione si pertenece a alguno de los siguientes grupos:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-select id="grupo_etnico_id" v-model="$v.form.grupo_etnico_id.$model" class="form-control"
                    :state="validateState('grupo_etnico_id')"
                    aria-describedby="grupo_etnico_id-live-feedback">
                        <b-form-select-option disabled value="">Por favor, seleccione</b-form-select-option>
                        <b-form-select-option v-for="item in lista_grupo_etnico" :key="item.id" :value="item.id">{{ item.descripcion }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="grupo_etnico_id-live-feedback">Este es un campo obligatorio.</b-form-invalid-feedback>
                </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-btn variant="success" class="mx-1 my-2" size="sm" type="submit"> Guardar</b-btn>
              </b-col>
            </b-row>
          
          
          
          </b-container>
          
          <b-container fluid v-else-if="paso == 4">
            <p class="text-center">
              <b>Proceso Exitoso</b>
            </p>
            <p>
              {{ user.data.primer_nombre }}, <br><br>
              {{mensaje}}
            </p>
            <b-button class="nav-link" :to="{ name: 'avanzar-nivel' }" variant="success" >Continuar</b-button>

          </b-container>
          
        </b-form>
        <hr>
       
        
      </b-overlay>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

    import { validationMixin } from 'vuelidate'
    // import { required, email, minLength } from '@vuelidate/validators'
    import { required, minLength, maxLength, numeric, regex } from "vuelidate/lib/validators";

    import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
  
    import SelectText from '@/components/Forms/SelectText.vue';
    import utils from '@/modules/utils';

    export default {
      components: {
        VueTypeaheadBootstrap,
        SelectText
      },
      mixins: [validationMixin],
      name: 'FinalizarNivel',
      data() {
          return {
              ESTRATOS: [1,2,3,4,5,6],
              GENEROS: ['Femenino','Masculino'],
              formError:[],
              form:{
                  id:null,
                  tipo_documento:null,
                  numero_documento:null,
                  nota_final:null,
                  nota_ife:null,
                  curso_actual:null,
                  estado_civil_id:null,
                  estrato:null,
                  fecha_nacimiento:null,
                  lugar_origen:null,
                  departamento_origen:null,
                  pais_origen:null,
                  lugar_residencia:null,
                  genero:null,
                  nivel_formacion:null,
                  nota_final:null,
                  ocupacion:null,
                  regimen_salud_id:null,
                  ocupacion_id:null,
                  discapacidad_id:null,
                  grupo_etnico_id:null,
                  nivel_educativo_id:null,
                  modulos_adquiridos:null,
                  idioma: null
              },
              paso:1,
              mensaje:null,
              ocupacionSearch: null,
              countrySearch: null,
              stateSearch:null,
              citySearch: null
          }
      },
      validations: {
        form: {
          tipo_documento: {
            required
          },
          numero_documento: {
            required,
            numeric,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },
          estado_civil_id: {
            required
          },
          fecha_nacimiento: {
            required,
            minValue: value => {              
              let minDate = new Date()
              minDate.setFullYear(minDate.getFullYear() - 100)
              const min = minDate.toISOString().split("T")[0];
              return value > min
            },
            maxValue: value => {              
              let maxDate = new Date()
              maxDate.setFullYear(maxDate.getFullYear() - 7)
              const max = maxDate.toISOString().split("T")[0];
              return value < max
            }
          },
          lugar_origen: {
            required
          },
          departamento_origen: {
            required
          },
          pais_origen: {
            required
          },
          estrato: {
            required
          },
          regimen_salud_id: {
            required
          },
          nivel_educativo_id: {
            required
          },
          ocupacion_id: {
            required
          },
          discapacidad_id: {
            required
          },
          grupo_etnico_id: {
            required
          },
          genero: {
            required
          },
        }
      },
      mounted () {
        this.traerTemaSiguiente();
        this.fetchTipoDocumento()
        this.fetchEstadoCivil()
        this.fetchRegimenSalud();
        this.fetchNivelFormacion();
        this.fetchOcupaciones();
        this.fetchGrupoEtnico();
        this.fetchDiscapacidades();
        this.fetchCountries();
        this.getInformation();
      },
      methods:{
        ...mapActions({
          fetchSolicitarAvance: 'academico/fetchSolicitarAvance',
          fetchCursoFinalizado: 'academico/fetchCursoFinalizado',
          fetchDisponibilidad: 'academico/fetchDisponibilidad',
          fetchTipoDocumento: 'tipoDocumento/fetchLista',
          fetchEstadoCivil: 'estadoCivil/fetchLista',
          fetchRegimenSalud: 'regimenSalud/fetchLista',
          fetchNivelFormacion: 'nivelFormacion/fetchLista',
          fetchOcupaciones: 'ocupacion/fetchLista',
          fetchGrupoEtnico: 'grupoEtnico/fetchLista',
          fetchDiscapacidades: 'discapacidad/fetchLista',
          fetchCiudades: 'ciudad/fetchLista',
          fetchInformacion: 'student/informacion',
          fetchCountries: 'ciudad/fetchCountries',
          fetchStates: 'ciudad/fetchStates',
          fetchCities: 'ciudad/fetchCities',
        }),
        ...mapMutations({
          setShowNotification: 'setShowNotification'
        }),
        getInformation() {
          this.fetchInformacion().then(response => {
            if (!this.form) this.form = {};
            for(var i in response) {
              this.form[i] = response[i];
            }
            // if (!this.form.nota_ife) {
            //   this.$router.push(`/avanzar-nivel`)
            // }
          }).catch(err => {
            console.error(err);
          })
        },        
        traerTemaSiguiente(){
          this.fetchDisponibilidad(false).then((result)=>{
            this.$router.push(`/`) // console.log("REGRESAR")
          }).catch((err) => {
            if (err && err.data && err.data.error && err.data.error === 'No tiene más temas pendientes.') {
              this.cursoFinalizado();
            } else {
              this.$router.push(`/`)// console.log("REGRESAR")
            }
          })
        },
        getCountries() {
          
        }, 
        cursoFinalizado(){
            this.fetchCursoFinalizado().then((result) => {
                if (result && result.dato && result.dato.curso_actual && result.dato.curso_actual.estudiante) {
                    this.$router.push(`/avanzar-nivel`)
                } else {
                  this.paso = 1;
                  // this.paso = 3;
                }
          }).catch((err) => {
            console.err(err)
          })
        },
        continuar(tipo) {
          if(tipo == 1) {
            history.back()
          } else {
            this.paso = tipo;
            this.scrollMeTo('top')
          }
        },
        validateState(name) {
          const { $dirty, $error } = this.$v.form[name];
          return $dirty ? !$error : null;
        },
        onSubmit() {
          this.$v.form.$touch();
          if (this.$v.form.$anyError) {
            return;
          }
          
          this.fetchSolicitarAvance(this.form).then((result) => {
            
            if (result && result.codigo == 'ok') {
              if (result && result.mensaje) {
                this.mensaje = result.mensaje;
                this.continuar(4);
              }
            }
          }).catch((err) => {
            console.error(err)
          })
          
        },
        scrollMeTo(refName) {
          var element = this.$refs[refName];
          var top = element.offsetTop;
          window.scrollTo(0, top);
        },

        isLetter(e) {
          let char = String.fromCharCode(e.keyCode); // Get the character
          if(utils.LETTER_REGEX.test(char)) return true; // Match with regex 
          else e.preventDefault(); // If not match, don't add to input text
        },
        isNumber(e) {
          let char = String.fromCharCode(e.keyCode); // Get the character
          if(/^[0-9]+$/.test(char)) return true; // Match with regex 
          else e.preventDefault(); // If not match, don't add to input text
        },
        reviewText(event, field) {
            let char = String.fromCharCode(event.keyCode);
            if(!utils.LETTER_REGEX.test(char)) {
              this.$v.form[field].$model = utils.eliminarCaracteres(this.$v.form[field].$model);
            }
        },
        reviewTextChange(event) {
            if(!utils.LETTER_REGEX.test(this.$v.form[field].$model)) {
              this.$v.form[field].$model = utils.eliminarCaracteres(this.$v.form[field].$model);
            }
        },
        ocupacionSeleccionada(item) {
          console.log(item);
        },
        hitCountry($event) {
          this.$v.form.pais_origen.$model = $event.country_name;
          this.fetchStates({name:$event.country_name});
        },
        hitState($event) {
          this.$v.form.departamento_origen.$model = $event.state_name;
          this.fetchCities({name:$event.state_name});
        }
          
      },
      computed:{
        ...mapState({
          user: state => state.auth.user,
          siguienteTema: state => state.academico.siguienteTema, 
          // sedeActual: state => state.academico.sedeActual, 
          // cursoActual: state => state.academico.cursoActual,
          // sedes: state  => state.sedes.lista,
          show_notification: state => state.show_notification,
          tipos_documentos: state => state.tipoDocumento.lista,
          estados_civiles: state => state.estadoCivil.lista,
          lista_regimen_salud: state => state.regimenSalud.lista,
          lista_nivel_formacion: state => state.nivelFormacion.lista,
          ocupaciones: state => state.ocupacion.lista,
          discapacidades: state => state.discapacidad.lista,
          lista_grupo_etnico: state => state.grupoEtnico.lista,
          lista_ciudades: state => state.ciudad.lista,
          siguienteTema: state => state.academico.siguienteTema, 
          countries: state => state.ciudad.countries,
          states: state => state.ciudad.states,
          cities: state => state.ciudad.cities,
        }),
        ...mapGetters({
          // getSede: 'sedes/getSede',
        }),
        tipodocumento() {
          if (this.form && this.form.tipo_documento) {
            let item = this.tipos_documentos.find(x => x.codigo == this.form.tipo_documento);
            if (item) {
              return item.descripcion;
            }
          }
          return null;
        },
        minDateFormat() {
          let now = new Date()
          now.setFullYear(now.getFullYear() - 80)
          return this.$moment(now).format("YYYY-MM-DD");
        },
        maxDateFormat() {
          let now = new Date()
          now.setFullYear(now.getFullYear() - 7)
          return this.$moment(now).format("YYYY-MM-DD");
          
        },
        minDate() {
          let now = new Date()
          now.setFullYear(now.getFullYear() - 80)
          return now;
        },
        maxDate() {
          let now = new Date()
          now.setFullYear(now.getFullYear() - 7)
          return now;
        },
        ciudadesLabel() {
          if (this.lista_ciudades) {
            return this.lista_ciudades.map(x => x.nombre)
          }
          return [];
        }
        
      },
      watch: {
        ocupacionSearch: function(newVal, oldVal) {
          var item = this.ocupaciones.find(x => x.descripcion == newVal);
          if (!item) {
            this.$v.form.ocupacion_id.$model = null;
          }
        },
        countrySearch: function(newVal, oldVal) {
          var item = this.countries.find(x => x.country_name == newVal);
          if (!item) {
            this.$v.form.pais_origen.$model = null;
          }
        },
        stateSearch: function(newVal, oldVal) {
          var item = this.states.find(x => x.state_name == newVal);
          if (!item) {
            this.$v.form.departamento_origen.$model = null;
          }
        },
        citySearch: function(newVal, oldVal) {
          var item = this.cities.find(x => x.city_name == newVal);
          if (!item) {
            this.$v.form.lugar_origen.$model = null;
          }
        }
      }
      
    }
  </script>
  
  <style scope>
  
      .perfil_wrapper .container{
        background-color: #fff;
        padding: 15px;
      }
      .list-group-item {
        padding: 0.25rem 0.75rem;
      }
      .form-label {
        font-weight: bolder;
        font-size: medium;

      }
  </style>